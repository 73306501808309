<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="compComponentesLista"
          sort-by="nome"
          class="elevation-10"
          :search="search"
          :loading="!compComponentesProgress.length"
          loading-text="Carregando..."
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-toy-brick-outline</v-icon>
              <v-toolbar-title>Componentes do Sistema</v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>
              <!-- <v-dialog v-model="dialog" max-width="500px"> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelecionarComponenteEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Componente</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
              <!-- </v-dialog> -->
            </v-toolbar>

            <v-container>
              <v-row dense>
                <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group mandatory active-class="primary">
                    <v-chip @click="listaComponentesAtivos = 'ativos'">
                      Ativos
                    </v-chip>
                    <v-chip @click="listaComponentesAtivos = 'inativos'">
                      Inativos
                    </v-chip>
                    <v-chip @click="listaComponentesAtivos = 'todos'">
                      Todos
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarComponenteEdicao(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  :color="metAtivoColor(item.ativo)"
                  v-bind="attrs"
                  v-on="on"
                  @click="metParaAtivacao(item)"
                >
                  {{ item.ativo ? "mdi-check-bold" : "mdi-close-thick" }}
                </v-icon>
              </template>
              <span>{{ item.ativo ? "Ativo" : "Inativo" }}</span>
            </v-tooltip>

            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";

const { mapGetters, mapState, mapActions } =
  createNamespacedHelpers("adminComponentes");

export default {
  components: { ConfirmDialogue },
  mixins: [],
  name: "AdminComponentesLista",
  data: () => ({
    listaComponentesAtivos: "ativos",
    search: "",
    dialog: false,
    headers: [
      { text: "Código", value: "id" },

      { text: "Componente", value: "componente", align: "start" },
      { text: "Campo", value: "campo", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState({
      staErro: "erro",
      staComponentes: "componentes",
      staComponenteSelecionado: "componenteSelecionado",
    }),
    ...mapGetters({
      getComponentesAtivos: "componentesAtivos",
      getComponentesInativos: "componentesInativos",
    }),

    compComponentesLista() {
      return this.listaComponentesAtivos == "ativos"
        ? this.getComponentesAtivos
        : this.listaComponentesAtivos == "inativos"
        ? this.getComponentesInativos
        : this.staComponentes;
    },
    compComponentesProgress() {
      return this.staComponentes;
    },
  },

  created() {
    register(this.$store);
    this.actListarComponentes();
  },

  watch: {
    staErro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "error",
          message: this.staErro,
        });

        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {},

  methods: {
    ...mapActions({
      actSelecionarComponente: "selecionarComponente",
      actListarComponentes: "listarComponentes",
      actAtivarComponente: "ativarComponente",
    }),

    metAtivoColor(ativo) {
      if (ativo) return "green darken-1";
      else return "red darken-1";
    },

    initialize() {},

    metSelecionarComponenteEdicao(componente) {
      this.actSelecionarComponente({ componente });
      this.$router.push({ name: "AdminComponentesForm" });
    },

    metParaAtivacao(componente) {
      this.actAtivarComponente({ componente });
    },

    metVoltar() {
      this.$router.back();
    },

    metRecarregar() {
      this.actListarComponentes();
      this.$root.snackbar.show({
        type: "info",
        message: "Lista Atualizada",
      });
    },
  },
};
</script>

<style>
/* .tooltip {
  display: flex;
  justify-content: space-around;
} */
</style>
